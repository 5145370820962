<template>
  <div class="contact-details py-8">
    <ContactAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="contact"
        name="contact"
        redirect="/hub/contact-directory"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="contact-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <avatar-form
              :user="contact"
              :upload="changeAvatar"
              :maxWidth="maxWidth"
              :maxHeight="maxHeight"
              @remove="contactAvatarRemoved"
              :showUploadBtn="hasDeletePermission"
            />

            <v-divider class="contact-details__divider"></v-divider>

            <ContactForm :contact="contact" :access-building="accessBuilding" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Contact
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import AvatarForm from '@/components/common/AvatarForm'
import ContactForm from '@/views/Home/Hub/Contacts/components/ContactForm'
import ContactAppbar from '@/views/Home/Hub/Contacts/components/ContactAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import UPLOAD_IMAGE_SIZE from '@/utils/enums/UploadImageSize'
import { access } from '@/utils/accessBuilding'

export default {
  name: 'ContactDetails',

  components: {
    ViewingTitle,
    AvatarForm,
    ContactAppbar,
    ContactForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      contact: (state) => state.contact.contactDetails,
      permissions: (state) => state.auth.permissions,
      buildingList: (state) => state.filter.buildingList,
    }),

    displayName() {
      return this.contact ? this.contact.name : null
    },

    hasDeletePermission() {
      return (
        validatePermissions([PERMISSION.CONTACTS_DELETE], this.permissions) &&
        this.accessBuilding
      )
    },

    accessBuilding() {
      return access(this.contact, this.buildingList)
    },

    maxWidth() {
      return UPLOAD_IMAGE_SIZE.CONTACT_IMAGE.width
    },

    maxHeight() {
      return UPLOAD_IMAGE_SIZE.CONTACT_IMAGE.height
    },
  },

  methods: {
    ...mapActions({
      getContactDetails: 'contact/getContactDetails',
      changeAvatar: 'contact/changeAvatar',
    }),

    ...mapMutations({
      clearContactDetails: 'contact/clearContactDetails',
      contactAvatarRemoved: 'contact/contactAvatarRemoved',
    }),

    async getContact() {
      this.loading = true
      await this.getContactDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getContact()
  },

  destroyed() {
    this.clearContactDetails()
  },

  watch: {
    $route() {
      this.getContact()
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
